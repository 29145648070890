
.courseContent-banner-container {
    width: 100%; 
    display: grid;
    grid-template-columns: repeat(10,1fr);
    grid-template-rows: auto;
    background-color: yellow;
    column-gap: 1px;
    row-gap: 1px;
    margin-top: 20px; 
  }

  .courseContent-primary-subheading {
    grid-column: 1/-1;
    grid-row: 1/2;
    height: auto;
    text-align: center;
    margin: 10px 20px 5px 10px;
    background-color: transparent;
    font-weight: 700;
    color: #fe9e0d;
    font-size: 1.15rem;
    justify-self:   center;
    align-self: end;
    }

.courseContent-primary-heading {
    grid-column: 1/-1;
    grid-row: 2/3;
    text-align: center;
    margin: 10px 10px 5px 0px;
    background-color: grey;
    font-size: clamp(2rem, 5vw, 4rem);
    color: #4c4c4c;
    }

.courseContent-primary-text {
    grid-column: 1/-1;
    grid-row: 3/4;
    text-align: center;
    background-color: red;
    font-size: clamp(1rem, 3vw, 1.5rem);
    color: #6a6a6a;
    margin: 1.5rem 0rem;
    align-self: flex-start;
    }

.chapters-grid {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates three columns */
    gap: 20px; /* Space between the boxes */
    background-color: #fe9e0d;
}

.chapter-box {
    background-color: #f0f0f0; /* Light gray background */
    border: 1px solid #ccc; /* Gray border */
    padding: 20px; /* Padding inside the boxes */
    text-align: center; /* Center the text */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow */
    border-radius: 10px; /* Rounded corners */
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s; /* Smooth transitions for interactions */
    min-height: 150px;  /* Minimum height for each chapter box */
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Vertically center the content */
    align-items: center;  /* Horizontally center the content */
}

.chapter-box h2 {
    color: #333;
    font-size: 24px;
}

.chapter-box:hover {
    background-color: #d7d2d2; /* Slightly darker on hover */
    cursor: pointer; /* Change cursor to indicate interactivity */
}

.chapter-box:active {
    transform: scale(0.98); /* Slightly scale down when clicked */
    box-shadow: 0 1px 3px rgba(0,0,0,0.2); /* Adjust shadow for depth effect */
}

@media (max-width: 768px) {
    .chapters-grid {
        grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
    }
}

@media (max-width: 480px) {
    .chapters-grid {
        grid-template-columns: 1fr; /* One column on very small screens */
    }
}

@media (max-width: 768px) {
    .chapter-box {
        min-height: 120px;  /* Smaller min-height for smaller screens */
    }
}

@media (max-width: 480px) {
    .chapter-box {
        min-height: 100px;  /* Even smaller for mobile devices */
    }
}

/* CourseContents.css */

.topics-container {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates three columns */
    gap: 20px; /* Space between the boxes */
    background-color: transparent;
}

.topic-centered-title {
    grid-column: 1/-1;    /* Span across all columns of the grid */
    text-align: center;   /* Horizontally center the text */
    width: 100%;          /* Ensure it takes up the full width */
    margin: 20px 0;       /* Vertical spacing for aesthetic separation */
    background-color: greenyellow; /* Visual styling to make it stand out */
    font-size: 2rem;      /* Larger font size for visibility */
    font-weight: bold;    /* Bold text for emphasis */
    padding: 10px 0;      /* Padding for better text framing */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    border-radius: 8px;   /* Rounded corners for a softer look */
}


.topics-grid {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 topics per row */
    gap: 20px;
    background-color: aqua;
}

.topic-box {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    display: flex;
    justify-content: space-between; /* Space between topic text and arrow icon */
    align-items: center;
    border-radius: 8px;
    cursor: pointer; /* Indicate that the box is clickable */
}

.topic-box:hover {
    background-color: #e0e0e0; /* Lighten background on hover */
}

.courseContent-banner-container {
    padding: 20px;
}

.topic-button-section{
    grid-column: 1/-1;
    z-index: 1;
    justify-self: center;
    align-self: top;

  }

.topic-secondary-button {
    padding: 1rem 2.5rem;
    background-color: orange;
    outline: none;
    border: none;
    border-radius: 6rem;
    font-size: 1.5rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: 0.2s;
  }
  .topic-secondary-button svg {
    margin-left: 0.75rem;
    font-size: 1.5rem;
  }
  .topic-secondary-button:hover {
    background-color: #e48f0f;
  }
  
  .instruction-container {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(10,1fr);
    grid-template-rows: repeat(10,1fr);
    height: 350px;
    gap: 5px; /* Space between the boxes */
    background-color: red;
}

.instruction-centered-title {
    grid-column: 1/-1;    /* Span across all columns of the grid */
    grid-row: 1/2;   
    text-align: center;   /* Horizontally center the text */
    width: 100%;          /* Ensure it takes up the full width */
    margin: 20px 0;       /* Vertical spacing for aesthetic separation */
    background-color: greenyellow; /* Visual styling to make it stand out */
    font-size: 2rem;      /* Larger font size for visibility */
    font-weight: bold;    /* Bold text for emphasis */
    padding: 10px 0;      /* Padding for better text framing */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    border-radius: 8px;   /* Rounded corners for a softer look */
}

.instruction-centered-text {
    grid-column: 1/-1;    /* Span across all columns of the grid */
    grid-row: 3/7;   
    text-align: center;   /* Horizontally center the text */
    width: 100%;          /* Ensure it takes up the full width */
    margin: 20px 0;       /* Vertical spacing for aesthetic separation */
    background-color: greenyellow; /* Visual styling to make it stand out */
    font-size: 1.2rem;      /* Larger font size for visibility */
    padding: 10px 0;      /* Padding for better text framing */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Subtle shadow for depth */
    border-radius: 8px;   /* Rounded corners for a softer look */
}

.instruction-button-section {
    display: grid;
    grid-column: 1/-1;  /* Spanning the full width */
    grid-row: 8/-1;     /* Assuming this places the section appropriately */
    justify-content: center; /* Horizontally centering the grid items */
    align-items: center;     /* Vertically centering the grid items */
    gap: 20px;               /* Space between buttons */
    padding: 20px;           /* Padding around the entire grid */
    grid-template-columns: repeat(2, auto); /* Creating two columns of auto width for the buttons */
}

  .instruction-secondary-button {
    width: 150px; /* Fixed width for both buttons */
    height: 50px; /* Fixed height for both buttons */
    padding: 10px 20px; /* Padding adjusted to vertically center the text */
    background-color: orange; /* Vivid, consistent background color */
    color: white; /* Text color */
    border: none; /* No border */
    border-radius: 30px; /* Fully rounded corners */
    font-size: 1rem; /* Appropriate font size */
    font-weight: bold; /* Bold text for readability */
    cursor: pointer; /* Cursor indicates button */
    display: flex; /* Using flex to vertically and horizontally align text */
    justify-content: center; /* Horizontally center the text */
    align-items: center; /* Vertically center the text */
    text-align: center; /* Ensure text is centered */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

  .instruction-secondary-button svg {
    margin-left: 0.75rem;
    font-size: 1.5rem;
  }
  .instruction-secondary-button:hover {
    background-color: #e48f0f;
  }
  
  @media (max-width: 768px) {
    .instruction-button {
        width: 120px; /* Smaller width for smaller screens */
        height: 40px; /* Smaller height for better fit */
    }
}

@media (max-width: 480px) {
    .instruction-button {
        width: 100px; /* Even smaller for mobile devices */
        height: 35px; /* Adjust height accordingly */
    }
}