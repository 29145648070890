:root {

    /* Color Palette */
    --background-color: #F8F8F1; /* Main background color */
    --font-color-primary: #4c4c4c; /* Primary text color */
    --font-color-secondary: #6a6a6a; /* Secondary text color */
    --accent-color: #e48f0f; /* Accent color for borders and highlights */
    --hover-color: #696866; /* Hover state color */
    --primary-color: #fe9e0d;
    --secondary-color: #4c4c4c;

    /* Fonts */
    --font-primary: 'Georgia', serif; /* Primary font */
    --font-secondary: 'Roboto', sans-serif; /* Secondary font for headings */

        /* Font Sizes */
        --font-size-sm: 0.875rem; /* Small font size */
        --font-size-default: 1rem; /* Default font size */
        --font-size-md: 1.25rem; /* Subheadings */
        --font-size-lg: 1.5rem; /* Large font size */
        --font-size-xl: 2.5rem; /* Extra large font size */
     --font-size-xxl: clamp(2rem, 3vw, 3rem); /* Large headings */

    /* Spacing */
    --spacing-xs: 0.5rem; /* Extra small spacing */
    --spacing-sm: 1rem; /* Small spacing */
    --spacing-md: 1.5rem; /* Medium spacing */
    --spacing-lg: 2rem; /* Large spacing */
    --spacing-xl: 3rem; /* Extra large spacing */

    /* Borders */
    --border-radius: 8px; /* Default border radius */
    --border-thin: 1px solid var(--font-color-primary); /* Default border */

    /* Shadows */
    --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Default shadow */

    /* Transition */
    --transition-fast: 0.2s ease-in-out; /* Fast transition */
    --transition-slow: 0.4s ease-in-out; /* Slow transition */

}

/* Global Typography Styles */
body {
    font-family: var(--font-secondary);
    font-size: var(--font-size-sm);
    color: var(--font-color-primary);
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-primary);
    color: var(--font-color-primary);
    margin: 0;
  }
  
  p {
    font-family: var(--font-secondary);
    font-size: var(--font-size-sm);
    color: var(--font-color-secondary);
    margin: 0 0 1rem 0;
  }

  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure body doesn't exceed the viewport width */
}

* {
    box-sizing: border-box;
}

img, video {
    max-width: 100%;
    height: auto;
}



.container {
    display: flex;
    flex-wrap: wrap;
}


* {
    outline: 0px solid red; /* Highlight all elements */
}