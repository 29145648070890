.footer {
    background: linear-gradient(135deg, #343a40, #3e444e); /* Subtle gradient */
    color: var(--font-color-secondary);
    padding: var(--spacing-lg) var(--spacing-md);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-lg);
    text-align: center; /* Default text alignment for all footer text */
}

.footer-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-lg);
    width: 100%;
    max-width: 1200px;
}

.footer-links h3, .footer-contact h3, .footer-social h3 {
    font-size: var(--font-size-md);
    color: var(--primary-color);
    margin-bottom: var(--spacing-sm);
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links ul li {
    margin: var(--spacing-xs) 0; /* Add spacing between items */
}

.footer-links ul li a {
    text-decoration: none;
    color: var(--font-color-secondary);
    transition: color var(--transition-fast);
    font-size: var(--font-size-default);
}

.footer-links ul li a:hover {
    color: var(--accent-color);
}

.footer-contact p {
    margin: var(--spacing-xs) 0;
    font-size: var(--font-size-default);
}

.footer-social .social-icons a {
    margin: 0 var(--spacing-xs);
    font-size: var(--font-size-lg);
    color: var(--font-color-secondary);
    transition: transform var(--transition-fast), color var(--transition-fast);
}

.footer-social .social-icons a:hover {
    transform: scale(1.2);
    color: var(--accent-color);
}

.footer-bottom {
    border-top: var(--border-thin);
    padding-top: var(--spacing-sm);
    text-align: center;
    font-size: var(--font-size-sm);
    color: var(--font-color-secondary);
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-container {
        grid-template-columns: 1fr; /* Stack sections vertically */
        gap: var(--spacing-md);
    }

    .footer-links ul li, .footer-contact p, .footer-social .social-icons a {
        text-align: left;
    }
}
