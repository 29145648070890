/* Importing Google Font similar to the one used in the template */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap'); */
/* Import the exact font from Google Fonts, assuming it's Roboto for demonstration */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.navbar {
    width: 100%;
    height: 80px; /* Adjust based on the example site */
    background-color: var(--background-color); /* Use the global variable */
    color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-around; /* This might vary based on design */
    /*  box-shadow: 0 4px 6px rgba(0,0,0,0.1); Soft shadow for a subtle depth */
    font-family: 'Roboto', sans-serif; /* Replace with the exact font used */
}

.nav-item {
    padding: 0 20px; /* Adjust spacing based on the design */
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.nav-item:hover {
    color: #04AA6D; /* Change to the exact hover color used */
}

.navbar:after {
  content: ''; /* Necessary for the pseudo-element to show */
  position: absolute;
  bottom: 0; /* Aligns the line at the bottom of the navbar */
  left: 50%; /* Positions the line starting from the center */
  width: 80%; /* Sets the line width to 80% of the navbar's width */
  height: 1px; /* Height of the line */
  background-color: #b0c0b0; /* Color of the line, set to black */
  transform: translateX(-50%); /* Centers the line */
}

.navbar-container {
    display: flex;
    align-items: center;
}

.navbar-logo {
    color: #4c4c4c; /* Specific blue color for the logo */
    font-size: 24px; /* Larger font size for logo */
    font-weight: 700; /* Bold weight for logo */
    cursor: pointer;
    text-decoration: none;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    height: 100%; /* Full height to align text vertically */
    align-items: center; /* Center text vertically */
    display: flex;
}

.nav-links li {
    padding: 10px;
}

.nav-links a {
    text-decoration: none;
    color: #6c757d; /* Updated to a lighter gray */
    transition: color 0.3s;
    font-weight: 400; /* Medium weight for menu items */
    font-size: 1.1rem; /* Larger font size for logo */

}

.nav-links a:hover, .nav-links a:focus {
    color: Black; /* Blue color for hover and focus states */
}

.navbar-button {
  background-color: transparent;
  color: #696866;
  border: none;
  padding: 5px 10px; /* Adjust padding as necessary */
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center; /* Center-align the content */
  justify-content: center;
  font-size: 0.8rem; /* Adjust font size as needed */
  margin-left: auto; /* Pushes the button to the edge of the next available space */
  margin-right: 50px; /* Increases the gap on the right side of the button */

}

.navbar-button span {
  font-size: 0.75rem; /* Adjust text size for visual balance */
  margin-top: 4px; /* Adjust spacing between icon and text */
}

.navbar-button:hover {
  background-color: transparent;
  color: black;
  border: 1px solid lightgray;
}

.popup-login {
  position: absolute;
  right: 10%; /* Position to your preference */
  top: 70px; /* Adjust to align with the navbar */
  width: 300px; /* Fixed width for uniformity */
  padding: 15px;
  background-color: #e4e9e4; /* Darker shade of #f4f9f4 */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 1050; /* Ensure it's above other content */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.popup-login p {
  color: #333;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  color: #6c757d; /* Updated to a lighter gray */

}

.close-btn-login{
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: #b0c0b0; /* A slightly darker greenish shade for buttons */
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}



.popup-login button:hover {
  background-color: #9da9a0; /* An even darker shade on hover */
}
.popup-signout {
  position: absolute;
  right: 10%; /* Position to your preference */
  top: 70px; /* Adjust to align with the navbar */
  width: 300px; /* Fixed width for uniformity */
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 1050; /* Ensure it's above other content */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.popup-signout p {
  color: #333;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.popup-signout button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: #0056b3;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.popup-signout button:hover {
  background-color: #003e7e;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.popup-signout {
  animation: fadeIn 0.3s ease-out forwards;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  background-color: transparent; /* A slightly darker greenish shade for buttons */
  color: #9da9a0;
  transition: background-color 0.2s;
}
