/* General About Section */
.about-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 10 columns */
  grid-template-rows: auto; /* Automatically adjust rows to fit content */
  gap: var(--spacing-md); /* Use global spacing */
  background-color: var(--background-color); /* Use the global variable */
  height: auto; /* Let content determine height */
  min-height: 70vh; /* Reduce minimum height */
  padding: var(--spacing-md); /* Use global spacing */
  position: relative;
}

.about-button-section {
  margin-top: var(--spacing-sm); /* Space above the button */
  display: flex;
  justify-content: center;
}

.about-secondary-button {
  padding: var(--spacing-sm) var(--spacing-lg); /* Use global spacing for padding */
  background-color: var(--primary-color);
  outline: none;
  border: none;
  border-radius: var(--border-radius); /* Use global border radius */
  font-size: var(--font-size-lg); /* Use global font size */
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: var(--transition-fast); /* Use global transition */
}

.about-secondary-button:hover {
  background-color: var(--accent-color);
}

/* Orange Subheading */
.about-orange-subheading {
  grid-column: 1 / -1; /* Span entire width */
  grid-row: 1 / 2; /* First row */
  font-size: var(--font-size-md); /* Use global font size */
  font-weight: bold;
  color: var(--primary-color); /* Use global color */
  text-transform: uppercase;
  text-align: center;
}

.about-right {
  grid-column: 6 / 10; /* Columns 6 to 10 */
  grid-row: 3 / 9; /* Rows 3 to 9 */
  text-align: center; /* Center text horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  margin: 0;
}

.about-primary-heading {
  font-size: var(--font-size-xxl); /* Use global font size for large headings */
  color: var(--font-color-primary);
  margin-bottom: var(--spacing-sm); /* Use global spacing */
  font-family: var(--font-primary); /* Use global font */
}

.about-subtext {
  font-size: var(--font-size-lg); /* Consistent text size */
  color: var(--font-color-secondary); /* Consistent text color */
  font-family: var(--font-primary); /* Use primary font */
  line-height: 1.5; /* Consistent line height */
  margin-top: var(--spacing-md); /* Global spacing */
  margin-bottom: var(--spacing-md);
  text-align: center; /* Align text consistently */
}

/* Left side list */
.about-list {
  grid-column: 1 / 5; /* Columns 1 to 5 */
  grid-row: 2 / 10; /* Rows 2 to 9 */
  list-style: none;
  padding: 0;
  padding-left: 3rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm); /* Use global spacing */
}

.about-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm); /* Use global spacing */
  margin: 0;
  padding: 0;
}

/* Icon container */
.about-icon-container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-icon {
  font-size: var(--font-size-lg); /* Use global font size */
  color: var(--primary-color); /* Use global color */
}

/* Content styling */
.about-content {
  text-align: left;
}

.about-title {
  font-size: var(--font-size-lg); /* Use global font size */
  color: var(--font-color-primary); /* Use global color */
  font-family: var(--font-primary); /* Use global font */
  font-weight: bold;
  margin: 0; /* Remove default margins */
}

.about-description {
  font-size: var(--font-size-md); /* Use global font size */
  color: var(--font-color-secondary); /* Use global color */
  line-height: 1.5;
  margin-top: var(--spacing-xs); /* Add spacing below the title */
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-container {
    grid-template-columns: 1fr; /* Single column */
    grid-template-rows: auto;
    gap: var(--spacing-lg); /* Use global spacing */
  }

  .about-right {
    grid-column: 1 / -1;
    grid-row: auto;
  }

  .about-list {
    grid-column: 1 / -1;
    grid-row: auto;
  }

  .about-item {
    flex-direction: column;
    text-align: center;
  }

  .about-icon {
    font-size: var(--font-size-lg); /* Adjust font size for smaller devices */
  }
}
