/* General Page Styling */
.learn-more-container {
  margin-top: 70px; /* Adjust for navbar height */
  padding: var(--spacing-lg) var(--spacing-md);
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

/* Header Section */
.learn-more-header {
  text-align: center;
}

.learn-more-header h1 {
  font-family: var(--font-primary);
  font-size: var(--font-size-xxl);
  color: var(--font-color-primary);
  margin-bottom: var(--spacing-sm);
}

.learn-more-header p {
  font-size: var(--font-size-lg);
  color: var(--font-color-secondary);
  margin-bottom: var(--spacing-md);
}

/* Features Section */
.learn-more-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  justify-items: center;
}

.feature-card {
  background-color: white;
  border-radius: var(--border-radius);
  padding: var(--spacing-md);
  text-align: center;
  box-shadow: var(--box-shadow);
  transition: var(--transition-fast);
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  font-size: var(--font-size-xxl);
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
}

.feature-card h3 {
  font-family: var(--font-primary);
  font-size: var(--font-size-lg);
  color: var(--font-color-primary);
  margin-bottom: var(--spacing-xs);
}

.feature-card p {
  font-size: var(--font-size-md);
  color: var(--font-color-secondary);
  line-height: 1.5;
}

/* Screenshots Section */
.learn-more-screenshots {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Six images in a row */
  gap: var(--spacing-xs); /* Reduce space between screenshots */
  padding: 0 var(--spacing-lg); /* Add padding to the left and right of the row */
  margin-top: var(--spacing-lg); /* Space above the screenshot row */
  justify-items: center; /* Center-align screenshots */
}

/* Screenshot Images */
.screenshot-image {
  width: 100%; /* Full width of the container */
  max-width: 230px; /* Fixed maximum width for uniform size */
  height: auto; /* Fixed height to maintain consistency */
  object-fit: cover; /* Ensures images fit the container without distortion */
  border-radius: var(--border-radius); /* Rounded corners for screenshots */
  box-shadow: var(--box-shadow); /* Add subtle shadow for depth */
  transition: transform var(--transition-fast); /* Smooth zoom effect */
}

.screenshot-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Screenshot Item */
.screenshot-item {
  text-align: center;
}

/* Screenshot Description */
.screenshot-description {
  margin-top: var(--spacing-xs);
  font-size: var(--font-size-md); /* Increased font size */
  font-weight: bold; /* Make the font bold */
  color: var(--font-color-secondary);
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .learn-more-screenshots {
    grid-template-columns: repeat(3, 1fr); /* Three screenshots per row */
    gap: var(--spacing-sm); /* Adjust spacing for smaller screens */
    padding: 0 var(--spacing-md); /* Reduce padding on smaller devices */
  }

  .screenshot-image {
    max-width: 200px; /* Adjust size for smaller devices */
  }
}

@media (max-width: 480px) {
  .learn-more-screenshots {
    grid-template-columns: repeat(2, 1fr); /* Two screenshots per row */
    gap: var(--spacing-sm); /* Reduce spacing for narrow screens */
    padding: 0 var(--spacing-sm); /* Minimal padding for smallest screens */
  }

  .screenshot-image {
    max-width: 150px; /* Reduce size for the smallest screens */
  }
}

/* Testimonials Section */
.learn-more-testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

/* Centered Header */
.testimonial-heading h2 {
  font-family: var(--font-primary);
  font-size: var(--font-size-xxl);
  color: var(--font-color-primary);
  margin-bottom: var(--spacing-md);
  text-align: center;
}

/* Testimonials List */
.testimonial-texts {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four testimonials in one row */
  gap: var(--spacing-md);
  justify-items: center;
  width: 100%;
  text-align: center; /* Center align all text */
}

/* Individual Testimonial */
.testimonial-item p {
  font-family: var(--font-secondary);
  font-size: var(--font-size-md);
  color: var(--font-color-secondary);
  line-height: 1.5;
}

/* Styling for the name in testimonials */
.testimonial-item span {
  font-size: var(--font-size-md); /* Use medium font size */
  color: var(--primary-color); /* Orange color from global variables */
  font-weight: bold; /* Make it bold for emphasis */
  display: block; /* Ensure it appears on a new line */
  margin-top: var(--spacing-xs); /* Add a little spacing above */
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .testimonial-texts {
    grid-template-columns: repeat(2, 1fr); /* Two testimonials per row */
  }
}

@media (max-width: 768px) {
  .testimonial-texts {
    grid-template-columns: 1fr; /* One testimonial per row */
  }
}

/* Call-to-Action Section */
.learn-more-cta {
  text-align: center;
  margin-top: var(--spacing-lg);
}

.learn-more-cta p {
  font-size: var(--font-size-lg);
  color: var(--font-color-primary);
  margin-bottom: var(--spacing-sm);
}

.cta-buttons {
  display: flex;
  gap: var(--spacing-md);
  justify-content: center;
}

.cta-button {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius);
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: var(--font-size-md);
  transition: var(--transition-fast);
}
/* Store Badge Styling */
.store-badge {
  width: 180px; /* Adjust size as needed */
  object-fit: contain; /* Ensure the badges scale proportionally */
  transition: transform var(--transition-fast); /* Smooth hover effect */
}

.store-badge:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .cta-buttons {
    flex-direction: column; /* Stack badges vertically */
    gap: var(--spacing-sm); /* Add spacing between badges */
  }

  .store-badge {
    width: 150px; /* Adjust size for smaller devices */
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .learn-more-testimonials {
      flex-direction: column;
      text-align: center;
  }

  .testimonial-image {
      margin-bottom: var(--spacing-md);
  }

  .cta-buttons {
      flex-direction: column;
      gap: var(--spacing-sm);
  }
}
