/* General Container */
.testimonial-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 10 columns */
  grid-template-rows: auto;
  gap: var(--spacing-md); /* Uniform gap using global spacing */
  background-color: var(--background-color); /* Use global background color */
  padding: var(--spacing-lg) var(--spacing-md); /* Global spacing for padding */
}

/* Subheading (orange "Testimonials") */
.testimonial-primary-subheading {
  grid-column: 1 / -1;
  text-align: center;
  font-size: var(--font-size-md); /* Global font size for subheadings */
  font-weight: bold;
  color: var(--primary-color); /* Global primary color */
  text-transform: uppercase;
}

/* Heading */
.testimonial-primary-heading {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  text-align: center;
  font-size: var(--font-size-xxl); /* Responsive font size */
  font-family: var(--font-primary); /* Global font for headings */
  color: var(--font-color-primary); /* Global text color */
  margin-bottom: var(--spacing-sm); /* Adjust spacing below heading */
}

/* Text Description */
.testimonial-primary-text {
  grid-column: 1 / -1;
  grid-row: 3 / 4;
  text-align: center;
  font-family: var(--font-primary); /* Global secondary font */
  font-size: var(--font-size-lg); /* Global medium font size */
  color: var(--font-color-secondary); /* Global secondary text color */
  line-height: 1.5;
  margin-top: 0rem; /* Reduce extra space above subheading */
  margin-bottom: var(--spacing-md); /* Adjust spacing below text */
}

/* Cards Container */
.testimonial-section-bottom {
  grid-column: 2 / 9; /* Centered in grid */
  grid-row: 5 / 10; /* Below heading and text */
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  gap: var(--spacing-lg) var(--spacing-md); /* Global gap variables */
  margin-top: var(--spacing-md); /* Adjust space above cards */
  justify-items: center;
  margin-top: 0;
}

/* Cards */
.testimonial-section-info {
  background-color: white; /* Slight tint using global background color */
  border-radius: var(--border-radius); /* Global border radius */
  box-shadow: var(--box-shadow); /* Global shadow */
  padding: var(--spacing-md); /* Global padding */
  text-align: center;
  display: flex;
  width: 90%; /* Ensures cards take up grid column space */
  height: auto; /* Let content determine height */
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center; /* Center-align content horizontally */
  gap: var(--spacing-xs); /* Add spacing between elements */
}

/* Image Container */
.info-boxes-img-container {
  width: 50%; /* Adjusted size */
  height: 0;
  padding-bottom: 50%; /* Aspect ratio for a perfect circle */
  margin: 0 auto var(--spacing-xs); /* Reduced spacing below the image */
  overflow: hidden;
  border-radius: 50%; /* Ensures the container is perfectly circular */
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.info-boxes-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images scale properly without distortion */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%; /* Ensures the image itself is circular */
}

/* Star Ratings */
.testimonials-stars-container {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-xs); /* Adjust spacing */
}

.testimonials-stars-container svg {
  font-size: var(--font-size-md); /* Global font size for icons */
  color: var(--primary-color); /* Same orange for stars */
  margin: 0 var(--spacing-xs); /* Adjust spacing between stars */
}

/* Name */
.testimonial-section-info h2 {
  font-family: var(--font-primary); /* Same font as About */
  font-size: var(--font-size-lg); /* Use global font size */
  color: var(--font-color-primary); /* Use global primary text color */
  margin: var(--spacing-xs) 0 var(--spacing-xs); /* Adjust spacing */
}

/* Testimonial Text */
.testimonial-section-info p {
  font-family: var(--font-secondary); /* Consistent font */
  font-size: var(--font-size-md); /* Consistent size with About */
  color: var(--font-color-secondary); /* Consistent description color */
  line-height: 1.5; /* Improved readability */
  margin: 0; /* Removed unnecessary extra spacing */
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonial-section-bottom {
    grid-template-columns: 1fr; /* One card per row */
  }

  .info-boxes-img-container {
    width: 80px;
    height: 80px;
  }

  .testimonials-stars-container svg {
    font-size: var(--font-size-sm); /* Adjust star size for smaller screens */
  }

  .testimonial-section-info h2 {
    font-size: var(--font-size-sm); /* Adjust font size */
  }

  .testimonial-section-info p {
    font-size: var(--font-size-sm); /* Adjust font size */
  }
}
