@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.home-banner-container {
  width: 100%;
  height: 90vh;
  display: grid;
  grid-template-columns: repeat(20, minmax(0, 1fr));
  grid-template-rows: 70px repeat(19, minmax(0, 1fr));
  background-color: var(--background-color);
  padding-top: var(--spacing-lg);
  margin: 0;
  column-gap: 0;
  position: relative;
}

.home-bannerImage-container {
    grid-column: 15 / -1;
    grid-row: 1 / -1;
    z-index: 1;
}

.home-text-section {
  grid-column: 2 / 12;
  grid-row: 3 / 10;
  z-index: 1;
  justify-self: center;
  align-self: center;
  font-size: var(--font-size-xl);
  color: var(--font-color-primary);
  font-family: var(--font-primary);
}

.home-subtext {
  grid-column: 2 / 10;
  grid-row: 11 / 15;
  z-index: 1;
  align-self: center;
  font-size: var(--font-size-lg); /* Use global variable for larger text size */
  max-width: 500px;
  color: var(--font-color-secondary); /* Use global variable for secondary text color */
  font-family: var(--font-primary); /* Use global font */
  line-height: 1.5; /* Consistent line height */
  margin: var(--spacing-md) 0; /* Use global spacing */}

.home-button-section {
    grid-column: 2 / 8;
    grid-row: 16 / 18;
    z-index: 1;
    justify-self: center;
    display: flex;
    gap: 1rem; /* Add spacing between the two buttons */
}

.store-badge {
    width: 180px; /* Fixed width for both */
    object-fit: contain; /* Ensures proportional scaling */
    transition: transform 0.2s ease-in-out;
}

.store-badge:hover {
  transform: scale(1.1);
  transition: var(--transition-fast); /* Use global transition */
}
.home-image-section {
    grid-column: 10 / 19;
    grid-row: 6 / 15;
    justify-self: center;
    width: 30vw;
    height: 30vw; /* Maintain circular aspect ratio */
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #e48f0f;
    z-index: 999;
}

.home-image-section > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; /* Ensures the image covers the container without distortion */
}

/* Responsive Design */
@media (max-width: 768px) {
    .home-button-section {
        flex-direction: column;
        gap: 1rem;
        grid-column: 1 / -1; /* Full width for smaller devices */
    }

    .store-badge {
        width: 200px; /* Adjust width for smaller devices */
    }

    .home-image-section {
        height: 80vw; /* Keep the image section square on smaller screens */
        width: 80vw;
    }

    .home-banner-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .home-image-section,
    .home-text-section,
    .home-subtext,
    .home-button-section {
        grid-column: 1 / -1;
        width: 80vw; /* Adjust the width for smaller devices */
        height: auto;
    }
}
