/* General Chapters Section */
.chapters-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 10 columns */
  grid-template-rows: auto; /* Adjust rows based on content */
  gap: var(--spacing-md); /* Consistent spacing using global variable */
  background-color: var(--background-color); /* Use the global background color */
  padding: var(--spacing-lg) var(--spacing-md);
}

/* Primary subheading (orange "Chapters") */
.chapters-primary-subheading {
  grid-column: 1 / -1; /* Full width */
  text-align: center;
  color: var(--primary-color); /* Use global primary color */
  text-transform: uppercase;
  font-size: var(--font-size-md); /* Use global subheading font size */
  font-weight: bold;
}

/* Primary heading */
.chapters-primary-heading {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  text-align: center;
  font-family: var(--font-primary); /* Same font as About section */
  font-size: var(--font-size-xxl); /* Consistent font size */
  color: var(--font-color-primary); /* Consistent color */
  margin-bottom: var(--spacing-sm); /* Adjust spacing below heading */
}

/* Primary description text */
.chapters-primary-text {
  grid-column: 1 / -1;
  grid-row: 3 / 4;
  text-align: center;
  font-family: var(--font-primary); /* Same font as About section */
  font-size: var(--font-size-lg); /* Consistent font size */
  color: var(--font-color-secondary); /* Consistent color */
  line-height: 1.5; /* Consistent line spacing */
  margin-top: 0rem; /* Reduce extra space above subheading */
  margin-bottom: var(--spacing-md); /* Adjust spacing below subheading */
}

/* Container for chapter cards */
.chapters-images-container {
  grid-column: 1 / -1; /* Full width */
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  grid-template-rows: repeat(2, auto); /* Two rows */
  gap: var(--spacing-lg) var(--spacing-md); /* Add spacing between cards */
  justify-items: center;
  padding: 0 var(--spacing-lg); /* Add padding on the left and right */
  margin-top: var(--spacing-lg);
}

/* Individual chapter card */
.chapters-section-info {
  background-color: white;
  border-radius: var(--border-radius); /* Use global border radius */
  box-shadow: var(--box-shadow); /* Use global shadow */
  padding: var(--spacing-md); /* Consistent padding */
  text-align: center;
  transition: var(--transition-fast); /* Use global transition */
  max-width: 480px; /* Maintain max width */
  width: 90%; /* Ensures cards take up grid column space */
  height: auto; /* Let content determine height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center; /* Center-align content horizontally */
  gap: var(--spacing-xs); /* Add spacing between elements */
}


.chapters-section-info:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Keep hover consistent */
}

/* Image container within chapter card */
.chapters-img-container {
  width: 50%; /* Adjusted size */
  height: 0;
  padding-bottom: 50%; /* Aspect ratio for a perfect circle */
  margin: 0 auto var(--spacing-xs); /* Reduced spacing below the image */
  overflow: hidden;
  border-radius: 50%; /* Ensures the container is perfectly circular */
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Image inside the circular container */
.chapters-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images scale properly without distortion */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%; /* Ensures the image itself is circular */
}


/* Card title */
.chapters-section-info h2 {
  font-family: var(--font-primary); /* Same font as About */
  font-size: var(--font-size-lg); /* Use global font size */
  color: var(--font-color-primary); /* Use global primary text color */
  margin: var(--spacing-xs) 0 var(--spacing-xs); /* Adjust spacing */
}

/* Card text */
.chapters-section-info p {
  font-family: var(--font-secondary); /* Consistent font */
  font-size: var(--font-size-md); /* Consistent size with About */
  color: var(--font-color-secondary); /* Consistent description color */
  line-height: 1.5; /* Improved readability */
  margin: 0; /* Removed unnecessary extra spacing */
}

/* Responsive Design */
/* Adjust layout for smaller screens */
@media (max-width: 768px) {
  .chapters-images-container {
      grid-template-columns: repeat(2, 1fr); /* Two cards per row */
      gap: var(--spacing-md); /* Reduce gap for smaller screens */
  }

  .chapters-section-info {
      width: 100%; /* Make cards take full available width */
      max-width: none; /* Remove max-width constraint */
  }
}

@media (max-width: 480px) {
  .chapters-images-container {
      grid-template-columns: 1fr; /* One card per row */
  }
}
